@import '@/assets/scss/_variables.scss';
@import '@/assets/scss/_colors.scss';
.Providers_wrapper {
  height: 100%;
}
.Providers_root {
  height: calc(100vh - 20px - 64px - 20px);
  .AppSection_tollbar {
    display: block;
  }
}
.Providers_container {
  height: 100%;
}
.Providers_btn {

  .Button_button_Icon {
    margin: 0 4px 0 0;
  }
}