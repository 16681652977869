@import '@/assets/scss/_variables.scss';
.ProvidersTable_Pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    row-gap: 12px;
    font-size: 18px;
    line-height: 21px;
    margin: 20px 0 0;
    @media only screen and (min-width: $mobile-width) {
        justify-content: space-between;
    }
    &Title {
        display: none;
        @media only screen and (min-width: $mobile-width) {
            display: block;
        }
    }
    &List {
        display: flex;
        gap: 2px;
        align-items: center;
    }
    &Item {
        --side: 20px;
        width: var(--side);
        height: var(--side);
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &--arrow {
            --side: 38px;
            background: rgba(#2AF3F3, .1);
            box-shadow: 0px 0px 10px rgba(42, 243, 243, 0.05);
            border-radius: 2px;
            color: #2AF3F3;
        }
        &--prev {
            margin: 0 10px 0 0;
            @media only screen and (min-width: $mobile-width) {
                margin: 0 20px 0 0;
            }
        }
        &--next {
            margin: 0 0 0 10px;
            @media only screen and (min-width: $mobile-width) {
                margin: 0 0 0 20px;
            }
        }
        &--dots {
            --side: 30px;
            cursor: default;
        }
        &--current {
            cursor: default;
            color: #2AF3F3;
        }
    }
}