@import '@/assets/scss/_variables.scss';
.CurrencyIcon {
  display: flex;
  align-items: center;
  cursor: pointer;

  &_icon {
    --diametr: 14px;
    position: relative;
    width: var(--diametr);
    height: var(--diametr);
    border-radius: 50%;
    background-color: #2AF3F3;
    margin: 0 4px 0 0;
    flex-shrink: 0;
    color: #2d4d70;

    @media only screen and (min-width: $large-width) {
      --diametr: 20px;
    }

    svg {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-size: 14px;
      border: 3px solid transparent;

      @media only screen and (min-width: $large-width) {
        border-width: 4px;
        font-size: 20px;
      }
    }

    .unknownIcon-root {
      color: #2D4D70;
      font-weight: 700;
      font-size: 10px;
      left: 3px;
    }
  }
}