@import '@/assets/scss/_mixins.scss';

.app-section-root {
  @include scrollbar;
}

body {
  &.dark {
    .app-section-title {
      text-shadow: 0 0 10px #102032;
    }
  }
}