@import '@/assets/scss/_variables.scss';
.ProvidersTable_Header {
  //display: flex;
  //flex-wrap: wrap;
  //margin: 0 0 12px;
  //z-index: 999;
  //position: relative;
  //@media only screen and (min-width: $mobile-width) {
  //  flex-wrap: nowrap;
  //}
  &Search {
    //margin: 0 0 14px;
    //width: 124px;
    //border-bottom: 1px solid rgba(166, 174, 191, 0.21);
    //outline: none;
    //color: inherit;
    //font-size: 12px;
    //line-height: 14px;
    //padding: 3px 0 3px 15px;
    //background-image: url('~@/assets/img/auth/search.svg');
    //background-size: 12px 12px;
    //background-position: 0 50%;
    //background-repeat: no-repeat;
    //flex-basis: 100%;
    //order: -9;
    @media only screen and (min-width: $mobile-width) {
        flex-basis: auto;
        order: unset;
        margin: 0 10px 0 20px;
    }
    &::placeholder {
        color: #A6AEBF;
    }
  }

  //&VisibilityButtons {
  //  display: flex;
  //  flex-basis: 66.67%;
  //
  //  @media only screen and (min-width: $mobile-width) {
  //    flex-basis: auto;
  //  }
  //}
  &VisibilityButton {
    //margin: 0 8px 0 0;
    //flex-basis: 50%;

    //@media only screen and (min-width: $mobile-width) {
    //  flex-basis: auto;
    //}

    //&:last-child {
    //  margin: 0;
    //}
  }

  &Spacer {
    flex-basis: 100%;
    display: none;

    @media only screen and (min-width: $mobile-width) {
      display: block;
    }
  }

  .ProvidersTable_Filter {
    flex-basis: 33.33%;
    padding: 0 8px 0 0;

    @media only screen and (min-width: $mobile-width) {
      flex-basis: auto;
      padding: 0;
    }
  }
}
.row-action-elements {
  @media only screen and (min-width: $tablet-width) {
    max-width: calc(100% - 183px);
  }
}