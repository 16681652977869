@import '@/sass/variables.scss';
@import '~vuetify/src/styles/styles.sass';



























svg {
  display: inline-block;
  fill: currentColor;
  width: 1em;
  height: 1em;
}
svg.icon-spin {
  animation: icon-spin 2s infinite linear;
}
@keyframes icon-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
