@import '@/assets/scss/_variables.scss';

.ProvidersEmpty {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;

    img {
        flex-grow: 1;
        object-fit: contain;
        max-width: 100%;
    }

    &_wrapperDesc {
        margin: 26px auto 16px;
        font-size: 14px;
        line-height: 134%;
        letter-spacing: 0.04em;
        color: #fff;
        max-width: 400px;
        width: 100%;

        @media only screen and (min-width: $tablet-width) {
            font-size: 18px;
            margin: 30px auto 14px;
        }

        span {
            font-weight: bold;
        }
    }

    &_add {
        height: 46px;
        padding: 8px 18px;
        border-radius: 4px;
        background: #2af3f3;
        box-shadow: 0px 0px 8.1747px rgba(42, 243, 243, 0.55);
        display: flex;
        align-items: center;
        color: #2d4d70;
        letter-spacing: 0.04em;
        text-transform: uppercase;
        text-decoration: none;
        font-weight: bold;
        font-size: 12px;
        line-height: 14px;
        justify-content: center;
        transition: 0.25s all ease-in-out;
        max-width: 316px;
        width: 100%;

        svg path {
            transition: 0.25s all ease-in-out;
        }

        span {
            width: 26px;
            height: 26px;
            border-radius: 28px;
            background: #2d4d70;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 0 0 8px;
        }

        &:hover {
            background: #a5ffff;
            color: #2d4d70;

            svg path {
                fill: #a5ffff;
                stroke: #a5ffff;
            }
        }
    }
}