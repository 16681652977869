@import './colors.scss';
@import './variables.scss';

@mixin centeringWithTransform {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

@mixin resetDefaultStyles {
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
}

@mixin defaultComponentWrapperStyles {
  box-shadow: 0px 18px 21px rgba(34, 39, 47, 0.34);
  border-radius: $default-border-radius;
  overflow: hidden;
  position: relative;
}

@mixin screenHeight {
  min-height: 100vh;
}

@mixin blurBGArea {
  background: rgba(223, 223, 223, 0.1);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}

@mixin linkHoverLine {
  position: relative;
  text-decoration: none;

  &:before {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 0;
    height: 1px;
    content: "";
    transition: 0.35s;
  }

  &:hover {
    &:before {
      width: 100%;
      left: 0;
      right: auto;
    }
  }

}

@mixin scrollbar {
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-thumb {
    border: 0 none #000;
    border-radius: 10px;
    background: rgba(#2af3f3, 0.3);
  }

  &::-webkit-scrollbar-thumb:active,
  &::-webkit-scrollbar-thumb:hover {
    background: rgba(#2af3f3, 0.7);
  }

  &::-webkit-scrollbar-track {
    border: 0 none #000;
    border-radius: 0;
    background: transparent;
  }

  &::-webkit-scrollbar-track:active {
    background: transparent;
  }
}

@mixin landingTitle {
  // color: $primaryText;
  font-weight: 700;
  font-size: 28px;
  line-height: 120%;
  text-transform: uppercase;
  &.title-h2 {
    font-size: 28px;
  }
  br {
    display: none;
  }
  @media only screen and (min-width: $tablet-width) {
    font-size: 56px;
    line-height: 100%;
    &.title-h2 {
      font-size: 34px;
    }
    br {
      display: block;
    }
  }
  @media only screen and (min-width: $desktop-width) {    
    font-size: 72px;
    &.title-h2 {
      font-size: 48px;
    }
  }
}

@mixin landingMontserratFont {
  font-family: 'Montserrat', sans-serif;
}