@import '@/assets/scss/_variables.scss';
.ProvidersTable {
  text-align: left;
  height: 100%;
  display: flex;
  flex-direction: column;
  &_Body{
    flex-grow: 1;
  }
  &_BodyEmpty{
    margin-top: 30px;
  }
  &_Row {
    background: #213B57;
    box-shadow: 0px 18px 21px rgba(34, 39, 47, 0.34);
    border-radius: 4px;
    margin: 0 0 8px;
    &:last-child{
      margin: 0
    }
    &SubHead {
      display: flex;
      align-items: center;
      flex-basis: 100%;
      background: linear-gradient(270deg, #2D4D70 11.98%, #213B57 44.89%, #14273C 100%);
      box-shadow: 0 18px 21px rgba(34, 39, 47, 0.34);
      padding: 9px 8px;
      @media only screen and (min-width: $tablet-width) {
          flex-basis: auto;
          background: transparent;
          box-shadow: none;
          padding: 0;
      }
    }
    &Head {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      @media only screen and (min-width: $tablet-width) {
        flex-wrap: nowrap;
        height: 40px;
        padding: 8px 14px;
        background: #39608B;
        box-shadow: 0 18px 21px rgba(34, 39, 47, 0.14);
      }
      &Icon {
        flex-shrink: 0;
        --side: 22px;
        width: var(--side);
        height: var(--side);
        background: #FFF;
        border-radius: 2px;
        margin: 0 6px 0 0;
        padding: 2px 4px;
        overflow: hidden;

        img {
          width:100%;
          height: 100%;
          object-fit: scale-down;
          object-position: center center;
        }
      }

      &Name {
        font-size: 14px;
        line-height: 16px;
        color: #fff;
        margin: 0 6px 0 0;

        @media only screen and (min-width: $tablet-width) {
          font-size: 16px;
          line-height: 19px;
        }
      }

      &SubName {
        font-size: 14px;
        line-height: 16px;
        color: rgba(#fff, .5);
        margin: 0 6px 0 0;

        @media only screen and (min-width: $tablet-width) {
          font-size: 16px;
          line-height: 19px;
        }
      }

      &Id {
        font-size: 12px;
        line-height: 14px;
        color: #2AF3F3;
        margin: 0 6px 0 0;
      }

      &Spacer {
        display: none;
        flex-grow: 1;

        @media only screen and (min-width: $tablet-width) {
          display: block;
        }
      }
    }

    &AddRout {
      margin: 10px 8px;
      align-items: center;
      display: inline-flex;
      justify-content: center;
      height: 30px;
      text-align: center;
      padding: 6px 14px;
      background: #2AF3F3;
      border-radius: 2px;
      font-size: 12px;
      line-height: 14px;
      color: #2D4D70;
      text-transform: uppercase;
      font-weight: bold;
      cursor: pointer;

      @media only screen and (min-width: $tablet-width) {
        margin: 0 13px 0 0;
      }

      &Circle {
        --side: 19px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: var(--side);
        height: var(--side);
        border-radius: 50%;
        background: #2D4D70;
        margin: 0 0 0 4px;
        font-size: 9px;
      }
    }

    &Activator {
      width: 36px;
      height: 18px;
      display: inline-flex;
      align-items: center;
      background: #2AF3F3;
      border-radius: 100vh;
      padding: 1px 3px;
      border: 1px solid transparent;
      cursor: pointer;
      transition: all .25s;
      margin: 0 8px;

      @media only screen and (min-width: $tablet-width) {
        margin: 0;
      }

      .ProvidersTable_Row--disable & {
        background: #243E5C;
        border-color: #2AF3F3;
      }

      span {
        --side: 12px;
        height: var(--side);
        width: var(--side);
        display: block;
        border-radius: 50%;
        background: #FFF;
        flex-shrink: 0;
        transition: all .25s;
        margin-left: calc(100% - 10px);

        .ProvidersTable_Row--disable & {
          margin-left: 0;
          background: #2AF0F1;
        }
      }
    }

    &Body {
      display: table;
      width: 100%;
    }
  }

  &_SubRow {
    position: relative;
    display: none;
    padding: 0 8px 10px;

    @media only screen and (min-width: $tablet-width) {
      padding: 0;
    }

    &:hover{
      z-index: 99;
    }

    &--visible {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      @media only screen and (min-width: $tablet-width) {
        display: table-row;
      }
    }

    &--titles {
      display: none;

      @media only screen and (min-width: $tablet-width) {
        display: table-row;
      }
    }
    &--allRoutes {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      @media only screen and (min-width: $tablet-width) {
        display: table-row;
      }
    }

    &--light {
      background: #254466;

      @media only screen and (min-width: $tablet-width) {
        background: #2D4D70;
      }
    }

    &:first-of-type {
      > * {
        border-top-color: transparent;
      }
    }
    > * {
      display: block;
      width: 50%;

      &:nth-of-type(2n) {
        text-align: right;
      }

      @media only screen and (min-width: $tablet-width) {
        display: table-cell;
        vertical-align: middle;
        height: 40px;
        width: 25%;
        border-top: 1px dashed rgba(#2AF3F3, .21);
        border-bottom: 1px solid transparent;
        padding: 6px 14px;
        text-align: center;

        &:nth-of-type(2n) {
          text-align: center;
        }

        &:first-child {
          text-align: left;
        }

        &:last-child {
          text-align: right;
        }
      }
    }

    &Title {
      font-weight: bold;
      font-size: 12px;
      line-height: 14px;
      text-transform: uppercase;

      .ProvidersTable_SubRow--main & {
        font-size: 10px;
        line-height: 12px;
        padding: 0 0 6px;
        border-bottom: 1px dashed rgba(#2AF3F3, .21);
        margin: 10px 0 8px;

        @media only screen and (min-width: $tablet-width) {
          display: none;
        }
      }
    }

    &Router {
      display: flex;
      align-items: center;

      &Icon {
        --side: 20px;
        width: var(--side);
        height: var(--side);
        background: #102032;
        border-radius: 1.6px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin: 0 4px 0 0;
        padding: 3px;

        svg {
          display: block;
          width: 100%;
          height: 100%;
        }
      }

      &Name {
        display: flex;
        align-items: center;
        font-size: 10px;
        line-height: 12px;
        color: #2AF3F3;
        text-decoration: underline;
        cursor: pointer;
        text-align: left;
        @media only screen and (min-width: $tablet-width) {
          font-size: 12px;
          line-height: 14px;
        }
      }
    }

    &Text {
      font-size: 10px;
      line-height: 12px;

      @media only screen and (min-width: $tablet-width) {
        font-size: 12px;
        line-height: 14px;
      }
    }
    &Currencies{
      display: flex;
      align-items: center;

      @media only screen and (min-width: $tablet-width) {
        justify-content: center;
      }

      .CurrencyIcon {
        margin: 0 10px 0 0;
        cursor: default;
        &:last-child{
          margin: 0;
        }
      }

      .InfoPseudoIcon {
        --diametr: 12px;
        width: var(--diametr);
        height: var(--diametr);
        background: #2AF3F3;
        display: block;
      }

      &Info {
        position: relative;
        z-index: 9;
      }
      &Tooltip{
        display: flex;
        flex-wrap: wrap;
        padding: 10px 14px;
        position: absolute;
        left: 50%;
        top: 100%;
        background: #2D4D70;
        box-shadow: 0px 18px 34px rgba(34, 39, 47, 0.55);
        border-radius: 4px;
        transform: translateX(-50%);
        row-gap: 6px;
        column-gap: 6px;
        min-width: 150px;
        margin: 10px 0 0;
        visibility: hidden;
        opacity: 0;
        transition: all .25s;

        .ProvidersTable_SubRowCurrenciesInfo:hover & {
          visibility: visible;
          opacity: 1;
        }

        &:before{
          content: "";
          position: absolute;
          width: 100%;
          height: 10px;
          bottom:100%;
          left: 0;
        }

        &:after{
          content: "";
          position: absolute;
          border-style: solid;
          border-width: 0 4px 4px 4px;
          border-color: transparent transparent #2D4D70 transparent;
          bottom: 100%;
          left: 50%;
          transform: translateX(-50%);
        }

        .CurrencyIcon {
          flex-basis: 54px;
          margin: 0;
        }
      }
    }

    &Button {
      height: 16px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 3px 6px;
      background: rgba(#2AF3F3, .1);
      box-shadow: 0 0 10px rgba(42, 243, 243, 0.05);
      border-radius: 2px;
      font-size: 10px;
      line-height: 12px;
      color: #2AF3F3;
      cursor: pointer;
      min-width: 40px;

      @media only screen and (min-width: $tablet-width) {
        font-size: 12px;
        line-height: 14px;
        padding: 6px 10px;
        height: 24px;
        min-width: 62px;
      }

      &Wrap{
        display: inline-flex;
        align-items: center;
      }

      svg {
        margin: 0 4px 0 0;
      }
    }

    &Activator {
      width: 24px;
      height: 12px;
      display: inline-flex;
      align-items: center;
      background: #2AF3F3;
      border-radius: 100vh;
      border: 1px solid transparent;
      padding: 1px 2px;
      cursor: pointer;
      margin: 0 0 0 8px;
      transition: all .25s;
      @media only screen and (min-width: $tablet-width) {
        margin: 0 0 0 24px;
      }
      .ProvidersTable_SubRow--disable & {
        background: #243E5C;
        border-color: #2AF3F3;
      }

      span {
        --side: 6px;
        height: var(--side);
        width: var(--side);
        display: block;
        border-radius: 50%;
        background: #FFF;
        flex-shrink: 0;
        transition: all .25s;
        margin-left: calc(100% - 6px);

        .ProvidersTable_SubRow--disable & {
          margin: 0;
          background: #2AF0F1;
        }
      }
    }
    &SpoilerButton {
      background: #2AF3F3;
      border-radius: 0 0 2px 2px;
      display: flex;
      height: 38px;
      color: #2D4D70;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      cursor: pointer;
      margin: 0 0 8px;

      &:last-child {
        margin: 0;
      }

      @media only screen and (min-width: $tablet-width) {
        display: none;
      }

      &Label {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-grow: 1;

        svg {
          margin: 0 4px 0 0;
          height: auto;
          width: 14px;
        }
      }

      &Arrow {
        background: #2D4D70;
        flex-basis: 58px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #2AF3F3;
      }
    }
  }
}